@import '../../style/variables';
@import '../../style/mixins';

.itemImg {
    width: 20rem;
    height: 20rem;
}
.itemWrapper {
    display: flex;
    justify-content: space-between;
    gap: 6rem;
    @media only screen and (max-width: $bp-medium) {
        gap: 2rem;
    }
}
.textWrapper {
    margin-right: auto;
    max-width: 42rem;
    & a {
        &:link,
        &:focus,
        &:active {
            display: inline-block;
        }
        &:hover {
            text-decoration: underline;
            text-decoration-color: $dark-color;
        }
        @media only screen and (max-width: $bp-small) {
            width: 75%;
        }
    }
}

.itemName {
    @include abel-font-normal;
    font-size: 2.4rem;
    line-height: 4.8rem;
    color: $dark-color;
    margin-top: -1.5rem;
    text-transform: uppercase;
    @media only screen and (max-width: $bp-small) {
        line-height: 2.8rem;
    }
}

.description {
    @include muli-font-normal;
    color: $dark-color;
    font-size: 1.4rem;
    line-height: 1.9rem;
    margin-bottom: 30px;
    @media only screen and (max-width: $bp-small) {
        margin-bottom: 1rem;
        width: 80%;
    }
}

.currentPrice {
    @include muli-font-semibold;
    color: $dark-color;
    font-size: 1.8rem;
    line-height: 2rem;
    margin-bottom: 18px;
    @media only screen and (max-width: $bp-small) {
        margin-bottom: 0.8rem;
    }
}

.flexBlock {
    display: flex;
    justify-content: flex-start;
}

.configWrapper {
    display: flex;
    flex-direction: column;
    gap: 11px;
    margin-right: 25px;
    @media only screen and (max-width: $bp-small) {
        margin-right: 1.5rem;
        gap: 0.7rem;
    }
    & p {
        @media only screen and (max-width: $bp-small) {
            display: flex;
            flex-direction: column;
        }
    }
}

.confName,
.confValue {
    @include muli-font-normal;

    font-size: 1.8rem;
    line-height: 2rem;
    display: inline-block;
}

.confName {
    margin-right: 12px;
    color: $dark-color;
}
.confValue {
    @include muli-font-light;
    text-transform: capitalize;
    color: $lightGrey;
}
.colorBlock {
    display: flex;
    align-items: center;
    @media only screen and (max-width: $bp-small) {
        align-items: flex-start;
    }
}
.color {
    display: block;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.flexBlockBtns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: auto;
    @media only screen and (max-width: $bp-medium) {
        align-items: flex-start;
        width: 100%;
    }
}
.addToFav {
    display: flex;
    align-items: center;
    gap: 2rem;
    @media only screen and (max-width: $bp-medium) {
        flex-direction: row-reverse;
    }
}
.favBtn,
.removeBtn {
    background: none;
}

.removeBtn {
    & svg {
        transition: all 0.2s ease-in;
        &:hover {
            transform: scale(1.1);
        }
    }
    @media only screen and (max-width: $bp-medium) {
        position: absolute;
        top: 2rem;
        right: 2rem;
    }
}

.addToFavTxt {
    @include muli-font-normal;
    font-size: 1.8rem;
}

.btnCart {
    @include abel-font-normal;
    display: block;
    padding: 2rem 5.5rem;
    font-size: 1.6rem;
    background: rgba(55, 63, 65, 1);
    border: 0.1rem solid rgba(55, 63, 65, 1);
    box-shadow: 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.25);
    color: rgb(255, 255, 255);
    border-radius: 0.4rem;
    transition: all 0.2s ease-in;

    @media only screen and (max-width: $bp-medium) {
        position: absolute;
        bottom: 4rem;
        right: 0.5rem;
    }
    @media only screen and (max-width: $bp-small) {
        position: absolute;
        top: 23rem;
        left: 0.5rem;
        right: auto;
        bottom: auto;
    }

    &:hover {
        background: rgb(167, 167, 167);
        transform: translateY(-3px);
    }

    &:active {
        transform: translateY(0);
    }
}
.largePadding {
    @media only screen and (max-width: $bp-small) {
        padding-bottom: 5.3rem;
    }
}

.favourite {
    &:not(.largePadding) {
        @media only screen and (max-width: $bp-small) {
            padding-bottom: 5.3rem;
        }
        @media only screen and (max-width: 483px) {
            padding-bottom: 2.8rem;
        }
        @media only screen and (max-width: 449px) {
            padding-bottom: 1rem;
        }
        @media only screen and (max-width: 405px) {
            padding-bottom: 0;
        }
    }
    & .configWrapper {
        .colorBlock {
            @media only screen and (max-width: $bp-small) {
                display: flex;
                align-items: center;
                flex-direction: row;
            }
        }

        @media only screen and (max-width: $bp-small) {
            margin-right: 0;
            gap: 0.7rem;
        }
        & p {
            @media only screen and (max-width: $bp-small) {
                display: block;
                // display: flex;
                // flex-direction: row;
                // justify-content: space-between;
            }
        }
    }
}
