@import '../../../style/mixins';
@import '../../../style/variables';

.mainContainer {
    @include content-container;
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    @media only screen and (max-width: $bp-medium) {
        gap: 2rem;
    }
    @media only screen and (max-width: $bp-small) {
        flex-direction: column;
        align-items: center;
        position: relative;
    }
}
