@import '../../style/mixins';
@import '../../style/variables';

.wrapper {
    display: flex;
    gap: 2rem;
    @media only screen and (max-width: $bp-medium) {
        & .left {
            width: 30%;
        }
        & .right {
            width: 70%;
        }
    }
    @media only screen and (max-width: $bp-small) {
        flex-direction: column;
        position: relative;
        & .left {
            width: 100%;
            & h1 {
                text-align: center;
                padding-bottom: 100px;
            }
        }
        & .right {
            width: 100%;
        }
    }
}
.left {
    width: 23.7%;
    display: flex;
    flex-direction: column;
    & h1 {
        @include abel-font-normal;
        font-size: $titles-fs;
        color: $dark-color;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    }
}
.right {
    width: 76.3%;
    display: flex;
    flex-direction: column;
}

.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: 0.7;
}
