@import "../../../../style/mixins";
@import "../../../../style/variables";

.wrapper {
    padding: 2rem  0;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    padding-top: 2rem;
    gap: 1rem;

    &Input {
        border: $border-dark;
        display: block;
        width: 50%;
        font-size: 1.8rem;
        padding: 5px;

        @media only screen and (max-width: $bp-medium) {
            width: 60%;
        }

        @media only screen and (max-width: $bp-small) {
            width: 100%;
        }
    }

    &InputUrl {
        width: 100%;
    }

    &Select {
        width: 30%;
        font-size: 1.8rem;
        padding: 5px;
        appearance: none;
        color: $lightGrey;
        border: $border-dark;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url('./img/arrow-expand.svg');
        background-repeat:no-repeat;
        background-position: 98% 50%;

        @media only screen and (max-width: $bp-medium) {
            width: 50%;
        }

        @media only screen and (max-width: $bp-small) {
            width: 80%;
        }

        & > option:checked {
            color: $dark-color;
        }
        & > option:not(:checked) {
            color: $lightGrey;
        }
    }

    &Label {
        text-transform: capitalize;
    }

    &Error {
        font-size: 1.8rem;
        color: #df2e2e;
    }

    &Buttons {
        display: flex;
        gap: 1rem;
        @media only screen and (max-width: $bp-small) {
            width: 80%;
           justify-content: center;
        }
    }

    &SubmitBtn,
    &ResetBtn {
        text-transform: uppercase;
        border: $border-dark;
        background-color: transparent;
        padding: 1rem 2rem;
        transition: all .2s ease-in;

        @media only screen and (max-width: $bp-small) {
            font-size: 1rem;
            padding: 1rem .5rem;
        }

        &:hover {
            background-color: $dark-color;
            color: $light-color;
        }
    }
}

.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: .7;
}
