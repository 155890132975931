@import '../../style/variables';
@import '../../style/mixins';
@import '../../style/animation';
.wrapper {
    border-bottom: 0.07rem solid $dark-color;
    padding-top: 2.2rem;
    padding-bottom: 1rem;
    min-width: 375px;
}
.header {
    display: flex;
    align-items: center;
    position: relative;
    @media only screen and (max-width: $bp-medium) {
        flex-wrap: wrap;
    }
}
.logo {
    width: 10%;
    @media only screen and (max-width: $bp-medium) {
        width: 20%;
    }
    @media only screen and (max-width: $bp-small) {
        width: 55%;
        display: flex;
        justify-content: flex-end;
    }
}
.header_nav {
    display: flex;
    align-items: center;
    color: $dark-color;
    width: 34%;
    @media only screen and (max-width: $bp-medium) {
        margin-top: 2rem;
        order: 3;
        width: 100%;
    }
    @media only screen and (max-width: $bp-small) {
        order: 3;
        width: 100%;
        // background: #000;
        position: relative;
        margin-top: 0;
    }
}
.header_list {
    display: flex;
    align-items: center;
    @media only screen and (max-width: $bp-medium) {
        width: 100%;
        justify-content: space-around;
    }
    @media only screen and (max-width: $bp-small) {
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        background-color: $light-color;
        margin-top: 2rem;
        max-height: 0;
        transition: max-height 0.5s ease-out;
        overflow: hidden;
    }
}
.header_list_item {
    margin-right: 3.8rem;
    font-size: $text-fs;
    padding-bottom: 0.3rem;
    border-bottom: 0.1rem solid transparent;
    @media only screen and (max-width: $bp-large) {
        margin-right: 2.5rem;
    }
    @media only screen and (max-width: $bp-small) {
        margin-right: 0;
        margin-bottom: 2rem;
    }
}
.header_list_item a {
    font-size: $text-fs;
    color: $dark-color;
    @media only screen and (max-width: $bp-small) {
        font-size: 2rem;
    }
}
.header_list_item_btn {
    background: transparent;
    font-size: $text-fs;
    color: $dark-color;
    line-height: 0;
    @media only screen and (max-width: $bp-small) {
        font-size: 2rem;
    }
}
.header_list_item:hover {
    border-bottom: 0.1rem solid $dark-color;
}
.header_list_item:last-child {
    margin-right: 0;
}
.header_list_item:first-child {
    position: relative;
    @media only screen and (max-width: $bp-small) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    & .catalogBtnWrapper {
        display: flex;
    }
}
.header_list_item:first-child:hover .dropCatalog {
    display: block;
    @media only screen and (max-width: $bp-small) {
        max-height: 100rem;
        transition: max-height 0.5s ease-in;
    }
}
.header_list_item:first-child:hover .catalog_wrapper {
    @media only screen and (max-width: $bp-small) {
        max-height: 100rem;
        transition: max-height 0.5s ease-in;
    }
}
.openBtn {
    margin-left: 0.4rem;
    background: transparent;
}
.header_buttons {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: auto;
    @media only screen and (max-width: $bp-medium) {
        width: 30%;
        & a:first-child {
            margin-left: auto;
        }
    }
}
.header_buttons a:not(:last-child) {
    margin-right: 3rem;
    background: transparent;
    @media only screen and (max-width: $bp-small) {
        margin-right: 2rem;
    }
}
.header_buttons_profile {
    background: transparent;

    & svg {
        fill: transparent;
        transition: all 0.2s ease-in;

        &:hover {
            fill: #000;
        }
    }
}
.group {
    position: relative;
    line-height: 1.8rem;
    width: 39rem;
    max-width: 39rem;
    height: 2.5rem;
    border-bottom: $dark-color 0.07rem solid;
    @media only screen and (max-width: $bp-large) {
        width: calc(100vw - 65rem);
    }
    @media only screen and (max-width: $bp-medium) {
        width: 50%;
        max-width: 50%;
    }
    @media only screen and (max-width: $bp-small) {
        order: 1;
        width: 70%;
        max-width: 70%;
        margin-left: 15%;
        margin-top: 2rem;
    }

    & > input {
        outline: none;
        color: #0d0c22;
        font-size: $text-fs;
        width: 100%;
        &::placeholder {
            color: $dark-color;
        }
    }
    & > div {
        position: absolute;
        top: 0;
        right: 0.2rem;
        width: 2.3rem;
    }
}
.catalog_wrapper {
    position: absolute;
    z-index: 100;
    right: -5.6rem;
    padding-top: 2.5rem;
    @media only screen and (max-width: $bp-medium) {
        padding-top: 1.5rem;
    }
    @media only screen and (max-width: $bp-small) {
        position: static;
        padding-top: 0;
        width: 100%;
        max-height: 0;
        transition: max-height 0.25s ease-in;
        overflow: hidden;
        display: block;
    }
}
.dropCatalog {
    border-top: $dark-color 0.5rem solid;
    padding-top: 2rem;
    display: none;
    padding: 3rem 4rem;
    background-color: $light-color;
    @media only screen and (max-width: $bp-small) {
        border-top: $dark-color 0.1rem solid;
        padding: 1.5rem 0;
        margin-top: 1rem;
        width: 100%;
        max-height: 0;
        transition: max-height 0.25s ease-out;
        overflow: hidden;
        display: block;
    }

    & a {
        transition: all 0.1s ease-in;
        &:hover {
            font-weight: 700;
        }
    }
}
.catalog_menu_list {
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: $bp-small) {
        align-items: center;
    }
    & a {
        @media only screen and (max-width: $bp-small) {
            font-size: 1.6rem;
        }
    }
}
.catalog_menu_list_item {
    margin-bottom: 2rem;
    color: $dark-color;
}
.catalog_menu_list_item:last-child {
    margin-bottom: 0;
}
.userIconWrp {
    display: flex;
    align-items: center;
}
.headerLinkHeart {
    &:hover {
        animation: pulse 1s infinite;
    }
}
.headerLinkCart {
    position: relative;
    transition: all 0.2s ease-in;

    & > span {
        position: absolute;
        display: flex;
        background-color: $dark-color;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 1rem;
        align-items: center;
        justify-content: center;
        line-height: 0;
        color: $light-color;
        right: -1rem;
        bottom: -0.5rem;
    }

    &:hover {
        transform: scale(1.05);
    }
}
.checkbox {
    display: none;
}

// burger

@media only screen and (max-width: $bp-small) {
    .hamburgerLines {
        display: block;
        height: 26px;
        width: 32px;
        position: absolute;
        top: 1rem;
        left: 0;
        z-index: 5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & .line {
            display: block;
            height: 4px;
            width: 100%;
            border-radius: 10px;
            background: #0e2431;
            &1 {
                transform-origin: 0% 0%;
                transition: transform 0.4s ease-in-out;
            }
            &2 {
                transition: transform 0.2s ease-in-out;
            }
            &3 {
                transform-origin: 0% 100%;
                transition: transform 0.4s ease-in-out;
            }
        }
    }
    .checkbox {
        position: absolute;
        display: block;
        height: 32px;
        width: 32px;
        top: 1rem;
        left: 0;
        z-index: 6;
        opacity: 0;
        cursor: pointer;
    }
    .header input[type='checkbox']:checked ~ .header_nav .header_list {
        max-height: 100rem;
        transition: max-height 0.5s ease-in;
    }

    .header input[type='checkbox']:checked ~ .hamburgerLines .line1 {
        transform: rotate(45deg);
    }

    .header input[type='checkbox']:checked ~ .hamburgerLines .line2 {
        transform: scaleY(0);
    }

    .header input[type='checkbox']:checked ~ .hamburgerLines .line3 {
        transform: rotate(-45deg);
    }
}
