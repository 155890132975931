@import '../../style/mixins';
@import '../../style/variables';

.paginationContainer {
    margin-top: 2.9rem;
    margin-bottom: 8rem;
    @include flex-center;
    & button {
        background: transparent;
    }
    & > button:first-child,
    & > button:last-child {
        position: relative;
        opacity: 0.7;
        padding: 2.1rem;
        transition: 0.3s;
        &:hover {
            opacity: 1;
            scale: 1.2;
        }
    }
    & > button:first-child {
        left: 3rem;
        @media only screen and (max-width: $bp-small) {
            padding: 2.1rem 1.5rem 2.1rem 0;
            margin-right: auto;
        }
    }
    & > button:last-child {
        right: 3rem;
        @media only screen and (max-width: $bp-small) {
            padding: 2.1rem 0 2.1rem 1.5rem;
            margin-left: auto;
        }
    }
    & .numbers {
        border-bottom: 1px solid $dark-color;
        & > button {
            box-sizing: border-box;
            @include muli-font-normal;
            font-weight: 300;
            font-size: 2rem;
            line-height: 3rem;
            padding: 1.6rem 3.7rem;
            border-bottom: 5px solid transparent;
            transition: 0.3s;
            @media only screen and (max-width: $bp-small) {
                padding: 1.6rem 2.2rem;
            }
            &:hover,
            &.active {
                border-bottom: 5px solid $dark-color;
                font-weight: 700;
            }
        }
    }
}
