@import "../../../style/mixins";
@import "../../../style/variables";
@import '../../../style/animation';

.subscribers {
    &Wrapper {
        padding: 1rem;
        display: flex;
        align-items: flex-start;
        position: relative;
        min-height: 80vh;
    }

    &Left {
        flex: 1 1 60%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @media only screen and (max-width: $bp-medium) {
            flex: 1 1 100%;
        }
    }

    &Right {
        flex: 1 1 40%;
        padding-left: 2rem;
        @media only screen and (max-width: $bp-medium) {
            display: none;
        }
        
        & th {
            text-align: left;
            padding-bottom: .5rem;
        }
    }

    &Form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &Input,
    &TextArea {
        border: $border-dark;
        display: block;
        width: 100%;
        font-size: 1.8rem;
        padding: 5px;
    }

    &TextArea {
        resize: none;
        min-height: 15rem;
    }

    &Label {
        text-transform: capitalize;
    }

    &Error {
        font-size: 1.8rem;
        color: #df2e2e;
    }

    &Buttons {
        display: flex;
        gap: 1rem;
    }

    &SubmitBtn,
    &ResetBtn {
        text-transform: uppercase;
        border: $border-dark;
        background-color: transparent;
        padding: 1rem 1.8rem;
        transition: all 0.2s ease-in;

        @media only screen and (max-width: $bp-small) {
            font-size: 1.4rem;
            padding: .5rem 1rem;
        }

        &:hover {
            background-color: $dark-color;
            color: $light-color;
        }
    }

    &Data {
        text-align: left;
    }

    &TextCenter {
        text-align: center;
    }

    &RemoveBtn {
        background: transparent;
        transition: transform .2s ease-in;

        &:hover {
            transform: scale(1.3);
        }
    }

    &PopUp {
        position: absolute;
        top: 0;
        left: 50%;
        padding: 1.2rem;
        font-size: 1.6rem;
        background-color: $dark-color;
        color: $light-color;
        display: none;
        transform: translate(-50%, 0);
        box-shadow: 0px 0px 5px $dark-color;
        animation: transformTopCenter 1s;
    }

    &PopUpShow {
        position: absolute;
        top: 0;
        left: 50%;
        padding: 1.2rem;
        font-size: 1.6rem;
        background-color: $dark-color;
        color: $light-color;
        display: block;
        transform: translate(-50%, 0);
        box-shadow: 0px 0px 5px $dark-color;
        animation: transformTopCenter 1s;
    }
}

.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: .7;
}
