$dark-color: #373f41;
$description-color: #8c8c8c;
$light-color: #ffffff;
$lightGrey: #8a9394;

$description-fs: 1.4rem;
$text-fs: 1.6rem;
$subtitles-fs: 2.4rem;
$titles-fs: 3.2rem;

// borders
$border-dark: 1px solid $dark-color;
$border-grey: 1px solid $lightGrey;

//break points
$bp-small: 31.25em; // 500px
$bp-medium: 50em; // 800px
$bp-large: 68.75em; // 1100px