@import '../../style/variables';
@import '../../style/mixins';

.unauthorized,
.empty {
    padding: 8rem 0.5rem;
    margin: 0 auto;
    width: max-content;
    font-size: 2rem;
}

.itemsList {
    width: 100%;
}

.bagLiItem {
    display: flex;
    gap: 80px;
    align-items: stretch;
    padding: 20px 34px 20px 0;

    border-bottom: 1px solid hsl(192, 8%, 24%, 0.25);

    &:first-of-type {
        border-top: 1px solid hsl(192, 8%, 24%, 0.25);
    }
    @media only screen and (max-width: $bp-medium) {
        flex-direction: column;
        gap: 2rem;
        padding: 1.5rem 0;
        position: relative;
    }
}

.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: 0.7;
}
