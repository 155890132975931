@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes pulse-big {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes transformTopCenter {
    0% {
        transform: translate(-50%, -100%);
    }
    100% {
        transform: translate(-50%, 0);
    }
}
@keyframes transformLeftOpacity {
    0% {
        opacity: 0;
        transform: translateX(-50%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}