@import './style/variables';
@import './style/mixins';

@import './style/normalize';
@import './style/typography';

body {
    @include muli-font-normal;
    font-size: 1.4rem;
}

// TECH CLASSES
.container {
    @include content-container;
}

// GLOBAL CAROUSEL STYLES
.carousel.carousel-slider {
    overflow: visible !important;
}

.carousel.carousel-slider .control-arrow {
    padding: 0 !important;
    background-color: $light-color !important;
    top: 50% !important;
    opacity: 1 !important;
    border-radius: 3px;

    &:hover {
        background-color: rgba($light-color, .5) !important;
    }
}

.carousel .control-next.control-arrow {
    transform: translate(55%, -50%) !important;
}

.carousel .control-prev.control-arrow {
    transform: translate(-55%, -50%) !important;
}

.carousel.carousel-slider .control-arrow {
    &::before {
        margin: 0 !important;
        border: 0 !important;
        background-size: 1.8rem 3.5rem;
        height: 3.5rem;
        width: 1.8rem;
    }
}

.carousel .control-prev {
    &::before {
        background-image: url('./img/arrow-prev.svg');
    }
}

.carousel .control-next {
    &::before {
        background-image: url('./img/arrow-next.svg');
    }
}
