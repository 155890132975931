@import '../../../style/variables';
@import '../../../style/mixins';

.carousel {
    margin-bottom: 8rem;
    @media only screen and (max-width: $bp-small) {
        margin-bottom: 4rem;
    }
    &Heading {
        text-align: center;
        margin-bottom: 3rem;
    }

    &Slide {
        display: flex;
        gap: 2rem;
    }

    &SlideLink {
        position: relative;
        width: 33.3333%;
        height: calc((100vw - (2rem * 2)) / 3);
        max-height: calc((118rem - (2rem * 2)) / 3);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    &Info {
        text-align: left;
        position: absolute;
        left: 2rem;
        bottom: 2rem;
        @media only screen and (max-width: $bp-medium) {
            left: 1.5rem;
            bottom: 1.5rem;
        }
        @media only screen and (max-width: $bp-small) {
            left: 1rem;
            bottom: 0.7rem;
        }
    }

    &Text {
        border-radius: 3px;
        width: fit-content;
        padding: 1rem;
        letter-spacing: 2px;
        text-transform: capitalize;
        @media only screen and (max-width: $bp-medium) {
            padding: 0.7rem;
        }
        @media only screen and (max-width: $bp-small) {
            padding: 0.5rem;
            font-size: 1rem;
        }
    }

    &TextBottom {
        margin-top: 1rem;
        @include roboto-font-bold;
        font-size: 2.4rem;
        @media only screen and (max-width: $bp-medium) {
            margin-top: 0.7rem;
        }
        @media only screen and (max-width: $bp-small) {
            margin-top: 0.3rem;
            font-size: 1.4rem !important;
        }
    }

    & :global .carousel.carousel-slider .control-arrow {
        width: 3.5rem;
        height: 6.4rem;
    }
}
