@import '../../../style/variables';
@import '../../../style/mixins';

.newIn {
    padding-top: 8rem;
    &Title {
        @include abel-font-normal;
        text-align: center;
        margin-bottom: 3rem;
        color: #373f41;
        font-size: 3rem;
    }
    &Wrp {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        column-gap: 2rem;
        row-gap: 2.2rem;
        justify-content: center;
    }
    &Item {
        width: calc(50% - 1rem);
        position: relative;
        @media only screen and (max-width: $bp-small) {
            width: 90%;
        }
    }
    &Img {
        width: 100%;
    }
    & .descriptionContainer {
        position: absolute;
        bottom: 9%;
        left: 9%;
        & > p {
            color: $light-color;
            background-color: $dark-color;
        }
        @media only screen and (max-width: $bp-medium) {
            bottom: 5%;
            left: 5%;
            & p:first-child {
                font-size: 2rem;
                padding: 1rem;
            }
            & p:last-child {
                font-size: 2.4rem;
            }
        }
    }
    &Text {
        font-size: $subtitles-fs;
        padding: 1.2rem 1.5rem;
        text-transform: capitalize;
        margin-bottom: 0.5rem;
    }
    &Price {
        display: inline-block;
        font-size: $titles-fs;
        font-weight: 700;
        padding: 0.6rem 1.5rem;
    }
}
