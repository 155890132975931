@import "../../../style/mixins";
@import "../../../style/variables";

.promotions {
    &Wrapper {
        padding: 0.5rem;
        display: flex;
        @media only screen and (max-width: $bp-medium) {
            flex-direction: column;
        }
    }

    &Left {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @media only screen and (max-width: $bp-medium) {
            order: 2;
            margin-top: 1rem;
        }
    }

    &Right {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-left: 1rem;
        @media only screen and (max-width: $bp-medium) {
            padding-left: 0;
            order: 1;
        }
        @media only screen and (max-width: $bp-small) {
            display: none;
        }
    }

    &Form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &Input,
    &TextArea {
        border: $border-dark;
        display: block;
        width: 100%;
        font-size: 1.8rem;
        padding: 5px;
    }

    &TextArea {
        resize: none;
        min-height: 10rem;
    }

    &Label {
        text-transform: capitalize;
    }

    &Error {
        font-size: 1.8rem;
        color: #df2e2e;
    }

    &Select {
        width: 50%;
        font-size: 1.8rem;
        padding: 5px;
        appearance: none;
        border: $border-dark;
        color: $lightGrey;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url("./img/arrow-expand.svg");
        background-repeat: no-repeat;
        background-position: 98% 50%;

        @media only screen and (max-width: $bp-small) {
            width: 70%;
        }

        & > option:checked {
            color: $dark-color;
        }
        & > option:not(:checked) {
            color: $lightGrey;
        }
    }

    &Buttons {
        display: flex;
        gap: 1rem;
    }

    &RemoveBtn {
        background: transparent;
        position: absolute;
        top: 1rem;
        right: 1rem;
        transition: transform .2s ease-in;

        &:hover {
            transform: scale(1.3);
        }
    }

    &SubmitBtn,
    &ResetBtn {
        text-transform: uppercase;
        border: $border-dark;
        background-color: transparent;
        padding: 1rem 1.8rem;
        transition: all 0.2s ease-in;

        @media only screen and (max-width: $bp-small) {
            font-size: 1rem;
            padding: 1rem 0.5rem;
        }

        &:hover {
            background-color: $dark-color;
            color: $light-color;
        }
    }

    &Data {
        position: relative;
        border: $border-dark;
        box-shadow: 0px 0px 5px $dark-color;
        cursor: grab;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &Img {
        width: 100%;
        height: auto;
    }

    &Info {
        text-align: left;
        position: absolute;
        left: 2.5rem;
        bottom: 1.5rem;
    }

    &Text {
        border-radius: 3px;
    }

    &Heading {
        padding: 0.5rem;
        max-width: max-content;
        font-size: 1.5rem !important;
    }

    &TextDescription {
        max-width: 70%;
        margin: 0.8rem 0;
        padding: 0.5rem;
        font-size: 1.3rem !important;
    }

    &Number {
        font-size: 2rem;
        color: $light-color;
        position: absolute;
        top: 1rem;
        left: 1rem;
    }
}

.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: .7;
}
