@import '../../../../style/mixins';
@import '../../../../style/variables';
@import '../../../../style/animation';

.AdminProducts {

    &Table {
        width: 100%;
        text-align: center;

        & td {
            padding: .5rem;
            text-transform: capitalize;
        }

        & td:nth-child(2) {
            display: flex;
            align-items: center;
            text-transform: capitalize;
            gap: 1rem;
            @media only screen and (max-width: $bp-medium) {
                display: block;
            }
            @media only screen and (max-width: $bp-small) {
                display: none;
            }
        }

        & td:nth-child(7) {
            & span {
                display: inline-block;
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
            }
        }

        & td:nth-child(10) {
            & a {
                &:link,
                &:active,
                &:visited {
                    @include abel-font-normal;
                    text-transform: uppercase;
                    color: $dark-color;
                    border: $border-dark;
                    padding: 2px 4px;
                    transition: all .25s ease-in;
                }

                &:hover {
                    background-color: $dark-color;
                    color: $light-color;
                }
            }
        }

        &Few {
            color: #ff0000;
            font-weight: 700;
            animation: pulse-big 1s infinite;
        }
    }

    &Img {
        max-width: 4.5rem;
        height: auto;
    }

    &RemoveBtn {
        background-color: transparent;
        & svg {
            transition: all 0.2s ease-in;
            &:hover {
                transform: scale(1.3);
            }
        }
    }

    &LargeNone {
        @media only screen and (max-width: $bp-large) {
            display: none;
        }
    }

    &MediumNone {
        @media only screen and (max-width: $bp-medium) {
            display: none;
        }
    }

    &SmallNone {
        @media only screen and (max-width: $bp-small) {
            display: none;
        }
    }

}

.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: .7;
}