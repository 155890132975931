@import '../../../style/variables';
@import '../../../style/mixins';

.topicHeaderWrapper {
    border-bottom: $border-grey;
    padding-bottom: 1.5rem;

    .topicHeader {
        @include muli-font-bold;
        font-size: 1.6rem;
        line-height: 2.4rem;
        color: $dark-color;
        margin-left: 0.1rem;
    }
    .topicDescription {
        @include muli-font-normal;
        color: $dark-color;
        font-size: 1.4rem;
        line-height: 2.5rem;
        margin-left: 2.1rem;
        margin-top: 1.5rem;
    }
}

.topicItem {
    border-bottom: 1px solid $border-dark;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    padding-top: 1.5rem;
    transition: height 1000ms;
    
    .topicItemHeader {
        @include muli-font-semibold;
        font-size: 1.6rem;
        line-height: 2rem;
        color: $dark-color;
        padding-left: 2rem;
        position: relative;
        cursor: default;
        user-select: none;

        &::before,
        &::after {
            content: '';
            width: 1.2rem;
            height: 2px;
            background-color: $dark-color;
            position: absolute;
            left: 0;
        }
        &::before {
            display: block;
            top: 0.9rem;
        }
        &::after {
            display: none;
            transform: rotate(90deg);
            top: 0.9rem;
        }
    }

    .topicItemText {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 1.5rem;

        .topicItemParagraph {
            @include muli-font-normal;
            color: $dark-color;
            font-size: 1.4rem;
            line-height: 2.5rem;
        }
    }
}

.topicItem.hiddenContent {
    .topicItemHeader::after {
        display: block;
    }
    .topicItemText {
        display: none;
    }
}
