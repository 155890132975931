@import 'index.scss';

.checkoutBtn {
    @include abel-font-normal;
    text-align: center;
    display: inline-block;
    font-size: 1.8rem;
    line-height: 2rem;
    padding: 1.5rem 3.6rem;
    color: rgb(255, 255, 255);
    background-color: $dark-color;
    border-radius: 4px;
    margin: 5rem auto 8rem;
    margin-left: 50%;
    transform: translateX(-50%);
}
