@import '../../style/_variables.scss';
@import '../../style/_mixins.scss';

.bagWrapper {
    @include content-container;
    @include flex-column-center;
}

.headerWrapper {
    @include flex-center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

    .bagHeader {
        @include muli-font-normal;
        color: $dark-color;
        font-size: 2.4rem;
        line-height: 2rem;
    }
    .totalPrice {
        @include muli-font-semibold;
        color: $dark-color;
        font-size: 2.4rem;
        line-height: 2rem;
    }
}

.itemsList {
    width: 100%;
}

.bagLiItem {
    display: flex;
    align-items: stretch;
    padding: 20px 34px 20px 0;
    border-bottom: 1px solid hsl(192, 8%, 24%, 0.25);
    &:first-of-type {
        border-top: 1px solid hsl(192, 8%, 24%, 0.25);
    }
    @media only screen and (max-width: $bp-medium) {
        flex-direction: column;
        padding: 2rem 0 1rem 0;
        position: relative;
    }
}

.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: 0.7;
}
