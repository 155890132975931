@import '../../../style/mixins';
@import '../../../style/variables';

.table {
    padding: 1rem;
    text-align: left;
    width: 100%;
    @media only screen and (max-width: $bp-medium) {
        font-size: 1.1rem;
    }
    @media only screen and (max-width: $bp-small) {
        font-size: 1.4rem;
    }
}

.row {

    & th {
        padding: 1rem;
    }

    &NoneLarge {
        @media only screen and (max-width: $bp-large) {
            display: none;
        }
    }

    &NoneMedium {
        @media only screen and (max-width: $bp-medium) {
            display: none;
        }
    }
    
    &NoneSmall {
        @media only screen and (max-width: $bp-small) {
            display: none;
        }
    }
    
    &TextCenter {
        text-align: center;
    }
}