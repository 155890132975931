@import '../../../../style/mixins';
@import '../../../../style/variables';

.productsContainer {
    @include flex-center-wrap;
    column-gap: 2rem;
    row-gap: 4.2rem;
    justify-content: flex-start;
    @media only screen and (max-width: $bp-medium) {
        justify-content: center;
    }
}
.product {
    width: calc(33.3333% - (4rem / 3));
    padding-top: calc(33.3333% - (4rem / 3));
    border-radius: 1px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    @media only screen and (max-width: $bp-medium) {
        width: calc(50% - (4rem / 2));
        padding-top: calc(50% - (4rem / 2));
    }
    @media only screen and (max-width: $bp-small) {
        width: 100%;
        padding-top: 100%;
    }

    & .productName {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        transition: 0.2s;
        transition: border 0s;
        & .title {
            @include muli-font-bold;
            font-size: 1.8rem;
            color: #ffffff;
            margin-bottom: 2.1rem;
            background: $dark-color;
            border-radius: 2px;
            padding: 0.4rem 0.8rem;
            text-transform: capitalize;
            text-align: center;
        }
        & .price,
        & .prevPrice {
            display: none;
            @include muli-font-bold;
            font-size: $titles-fs;
            color: #ffffff;
            margin-bottom: 2rem;
        }
        & .prevPrice {
            font-size: 2.3rem;
            @include muli-font-normal;
            text-decoration: line-through;
            color: $lightGrey;
        }
        & a {
            display: none;
            @include abel-font-normal;
            font-size: $text-fs;
            color: $dark-color;
            padding: 1rem 3.5rem;
            background: #ffffff;
            border: 1px solid $dark-color;
            border-radius: 4px;
            text-transform: uppercase;
        }
        & .removeBtn {
            display: none;
            position: absolute;
            top: 2rem;
            right: 2rem;
            background: none;
            fill: $light-color;
            & svg {
                transition: all 0.2s ease-in;
                &:hover {
                    transform: scale(1.3);
                }
            }
        }
    }
    &:hover {
        scale: 1.02;
        & .productName {
            background: rgba($color: #000000, $alpha: 0.6);
            border: 4px solid rgba(38, 37, 36, 0.7);
            border-radius: 1px;
            justify-content: center;
            & .title {
                background: transparent;
            }
            & .price {
                display: block;
            }
            & .prevPrice {
                display: block;
            }
            & .removeBtn {
                display: block;
            }
            & a {
                display: block;
            }
        }
    }
}

.noProductMatch {
    text-align: center;
    height: 70vh;
    position: relative;
    & p {
        position: absolute;
        top: 50%;
        transform: translatY(-50%);
        width: 100%;
        font-size: 2.1rem;
    }
}
