@import "../../../style/variables";
@import "../../../style/mixins";

.partners {
    padding: 4rem 0 6rem 0;

    & h2 {
        text-align: center;
    }

    &List {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 2rem 4rem;
        padding: 1rem 8rem;
        @media only screen and (max-width: $bp-large) {
            gap: 8rem 4rem;
        }
        @media only screen and (max-width: $bp-medium) {
            gap: 8rem 4rem;
        }
    }

    &Item {
        flex: 1 1 calc((100% - 2 * 4rem) / 3);
        position: relative;
        @media only screen and (max-width: $bp-medium) {
            flex: 1 1 calc((100% - 4rem) / 2);
        }
        @media only screen and (max-width: $bp-small) {
            flex: 1 1 calc(100%);
        }
    }

    &Item:hover &FrontSide {
        transform: rotateY(-180deg);
        @media only screen and (max-width: $bp-large) {
            transform: rotateY(0deg);
        }
    }

    &Item:hover &BackSide {
        transform: rotateY(0deg);
        z-index: 3;
    }

    &Side {
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        width: 100%;
        transition: all 0.8s ease;
        border-radius: 5px;
        will-change: transform;
        box-shadow: 0px 0px 5px $dark-color;
        background-color: $light-color;
    }

    &BackSide {
        transform: rotateY(180deg);
        transition: all 0.8s ease;
        text-align: center;
        padding: 3rem 2rem;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        @media only screen and (max-width: $bp-large) {
            transform: rotateY(0);
            height: 120%;
            box-shadow: none;
            background-color: transparent;
            padding: 0;
        }
        @media only screen and (max-width: 56.25em) {
            height: 130%;
        }
        @media only screen and (max-width: $bp-medium) {
            height: 120%;
        }
        @media only screen and (max-width: 37.5em) {
            height: 130%;
        }
        @media only screen and (max-width: $bp-small) {
            height: 120%;
        }
    }

    &Image {
        width: 100%;
        height: auto;
        max-height: 100%;
        border-radius: 5px;
    }

    &Desk {
        font-size: 1.5rem;
        margin-bottom: 1rem;

        & span {
            font-size: 2rem;
        }
        @media only screen and (max-width: $bp-large) {
            display: none;
        }
    }

    &Link {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 1rem 2rem;
        color: $dark-color;
        transition: all .25s ease-in;
        font-size: 1.3rem;
        text-transform: uppercase;
        border: $border-dark;
        border-radius: 5px;
        cursor: pointer;
        @media only screen and (max-width: $bp-large) {
            top: 100%;
            transform: translate(-50%, -150%);
            font-size: 1.1rem;
        }
        @media only screen and (max-width: $bp-medium) {
            padding: .8rem 1rem;
            font-size: 1rem;
        }
        @media only screen and (max-width: $bp-small) {
            font-size: 1.1rem;
        }

        &:hover {
        background-color: $dark-color;
        color: $light-color;
        }
    }
}
