@import "../../../style/mixins";
@import "../../../style/variables";

.partners {
    &Wrapper {
        padding: 0.5rem;
        display: flex;
        @media only screen and (max-width: $bp-medium) {
            flex-direction: column;
        }
    }

    &Left {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @media only screen and (max-width: $bp-medium) {
            order: 2;
            margin-top: 1rem;
        }
    }

    &Right {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-left: 1rem;
        @media only screen and (max-width: $bp-medium) {
            padding-left: 0;
            order: 1;
        }
        @media only screen and (max-width: $bp-small) {
            display: none;
        }
    }

    &Form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &Input {
        border: $border-dark;
        display: block;
        width: 100%;
        font-size: 1.8rem;
        padding: 5px;
    }

    &Label {
        text-transform: capitalize;
    }

    &Error {
        font-size: 1.8rem;
        color: #df2e2e;
    }

    &Buttons {
        display: flex;
        gap: 1rem;
    }

    &RemoveBtn {
        background: transparent;
        position: absolute;
        top: 1rem;
        right: 1rem;
        transition: transform .2s ease-in;

        &:hover {
            transform: scale(1.3);
        }
    }

    &SubmitBtn,
    &ResetBtn {
        text-transform: uppercase;
        border: $border-dark;
        background-color: transparent;
        padding: 1rem 1.8rem;
        transition: all 0.2s ease-in;

        @media only screen and (max-width: $bp-small) {
            font-size: 1rem;
            padding: 1rem 0.5rem;
        }

        &:hover {
            background-color: $dark-color;
            color: $light-color;
        }
    }

    &List {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    &Item {
        flex: 1 1 calc(50% - 1rem);
        border-radius: 5px;
        position: relative;
        box-shadow: 0px 0px 5px $dark-color;
    }

    &Image {
        width: 100%;
        height: auto;
    }

}

.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: .7;
}
