
.wrapper {
    padding-top: 2rem;
    position: relative;
}

.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: .7;
}