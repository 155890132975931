.h2,
.h3,
.h4 {
    &--dark {
        background-color: $dark-color;
        color: $light-color;
    }
}


.h2 {
    @include abel-font-normal;
    font-size: $titles-fs;
    text-transform: uppercase;
}

.h3 {
    @include abel-font-normal;
    text-transform: uppercase;
    font-size: 2rem;
}

.h4 {
    font-size: 1.8rem;
}

.p {

    &--dark {
        background-color: $dark-color;
        color: $light-color;
        font-size: $text-fs;
    }
}