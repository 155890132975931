@import '../../style/mixins';

.infoBody {
 @include muli-font-light;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 31rem;
  width: 100%;
  height: 4rem;
  position: relative;
  border: 0.1rem solid black;
  color: dimgray;
  border-radius: 0.5rem;
  background-color: gainsboro ;
  opacity: 0;
  animation: show 1.5s;
  animation-fill-mode: forwards;
}
.block-show {
  animation: show 1s;
  animation-fill-mode: forwards;
}
.block-hide {
  animation: hide 1s;
  animation-fill-mode: forwards;
}
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.close {
  position: absolute;
  top: 0.1rem;
  right: 1rem;
  background-color: gainsboro;
}