@import '../../style/variables';
@import '../../style/mixins';

.ErrorPage {
	@include centerBlock;
	top: 45%;
	text-align: center;
	font-size: 1.8rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}