@import '../../style/variables';
@import '../../style/mixins';

.title {
    @include abel-font-normal;
    color: #373f41;
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    margin-top: 2rem;
}

.boxInfo {
    margin: 2rem 0 4rem 0;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(199, 199, 199);
    background-color: rgb(252, 252, 252);
    box-shadow: 0rem 0.2rem 0.3rem rgba(0, 0, 0, 0.12);
    border-radius: 0.5rem;
    &Title {
        @include muli-font-bold;
        font-size: $text-fs;
        color: $dark-color;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    &Name {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        background: transparent;
    }
}
.headerPrice {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0 2rem;
}
.orderDataList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    gap: 2rem;
    margin: 1rem 0 3rem 0;
    & .orderDataItem:first-child {
        grid-column: 1 / 3;
        grid-row: 1;
    }
    & .orderDataItem:nth-child(2) {
        grid-column: 2 / 4;
        grid-row: 1;
    }
}
.orderDataItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $dark-color;
    font-size: 1.8rem;
    line-height: 2.9rem;
    text-transform: capitalize;
}
.orderDataPlaseholder {
    color: rgba(92, 94, 96, 0.5);
    font-size: 1.4rem;
    margin-bottom: -1rem;
    text-transform: capitalize;
}
.editBtn {
    margin: 1rem auto;
    @include abel-font-normal;
    display: block;
    padding: 1rem 5rem;
    font-size: 1.6rem;
    background: rgba(55, 63, 65, 1);
    border: 0.1rem solid rgba(55, 63, 65, 1);
    box-shadow: 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.25);
    color: rgb(255, 255, 255);
    border-radius: 3rem;
    transition: all 0.2s ease-in;
}
.editBtn:hover {
    background: rgb(255, 255, 255);
    color: rgba(55, 63, 65, 1);
}
.noOrders {
    margin: 3rem 0;
    @include muli-font-normal;
    text-align: center;
    font-size: $subtitles-fs;
}
.products {
    &Table {
        width: 100%;
        text-align: center;
        @media only screen and (max-width: $bp-small) {
            &Hide {
                display: none;
            }
        }

        & td {
            padding: 0.5rem;
            text-transform: capitalize;
        }

        & td:nth-child(2) {
            display: flex;
            align-items: center;
            text-transform: capitalize;
            gap: 1rem;
            & span {
                text-align: start;
            }
        }

        & td:nth-child(7) {
            & span {
                display: inline-block;
                width: 2rem;
                height: 2rem;
                border-radius: 50%;
            }
        }

        & td:last-child {
            & a {
                &:link,
                &:active,
                &:visited {
                    @include abel-font-normal;
                    text-transform: uppercase;
                    color: $dark-color;
                    border: $border-dark;
                    padding: 2px 4px;
                    transition: all 0.25s ease-in;
                }

                &:hover {
                    background-color: $dark-color;
                    color: $light-color;
                }
            }
        }
    }

    &Img {
        max-width: 4.5rem;
        height: auto;
    }
}

.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: 0.7;
}
