@import '../../../style/_variables.scss';
@import '../../../style/_mixins.scss';

.numInputBlock {
    display: flex;
    width: 70px;
    height: 50px;
    border: $border-dark;
    border-radius: 2px;
    align-self: flex-end;

    .numInput {
        @include flex-center;
        @include muli-font-semibold;
        font-size: 1.8rem;
        line-height: 3.2rem;
        color: $dark-color;
        width: 40px;

        border-right: $border-dark;
        text-align: center;
    }

    .numInputBtns {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .counterBtn {
        @include flex-center;
        height: 100%;
        background: none;
        cursor: default;

        &:first-of-type {
            border-bottom: $border-dark;
        }
    }
}
