@import "../../../../style/mixins";
@import "../../../../style/variables";
.wrapper {
    padding: .5rem;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
    padding-top: 2rem;
    gap: 1rem;

    &Input {
        border: $border-dark;
        display: block;
        width: 50%;
        font-size: 1.8rem;
        padding: 5px;

        @media only screen and (max-width: $bp-medium) {
            width: 80%;
        }

        @media only screen and (max-width: $bp-small) {
            width: 100%;
        }
    }

    &Label {
        text-transform: capitalize;
    }

    &Error {
        font-size: 1.8rem;
        color: #df2e2e;
    }

    &Buttons {
        display: flex;
        gap: 1rem;
    }

    &SubmitBtn,
    &ResetBtn {
        text-transform: uppercase;
        border: $border-dark;
        background-color: transparent;
        padding: 1rem 2rem;
        transition: all .2s ease-in;
        @media only screen and (max-width: $bp-medium) {
            padding: 1rem .5rem;
            font-size: 1rem;
        }
        @media only screen and (max-width: $bp-small) {
            font-size: 1rem;
            padding: 1rem .5rem;
        }

        &:hover {
            background-color: $dark-color;
            color: $light-color;
        }
    }

}
.loader {
    justify-content: center;
    align-items: center;
    height: 70vh;
    opacity: .7;
}