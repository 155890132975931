@import '../../style/variables';
@import '../../style/mixins';

.accent_color {
    font-size: $description-fs;
    color: red;
}
.section {
    width: 60%;
    @media only screen and (max-width: $bp-medium) {
        width: 55%;
    }
    @media only screen and (max-width: $bp-small) {
        width: 100%;
    }
}
.shipping_title {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    color: $dark-color;
    font-size: 2.4rem;
    line-height: 4.8rem;
    text-transform: uppercase;
}
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
    border-top: 1px solid $dark-color;
    border-bottom: 1px solid $dark-color;
}
.main_inputs {
    padding-bottom: 2rem;
    border-bottom: 1px solid $dark-color;
}
.inputs_wrapper {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $bp-medium) {
        flex-direction: column;
    }
}
.input {
    border: 1px solid $description-color;
    margin-bottom: 2rem;
    width: 100%;
    border-radius: 0.5rem;
    color: $description-color;
    padding: 0.8rem 1rem 0.8rem 2rem;
}
.input:first-child {
    margin-right: 2rem;
}
.input_label {
    position: relative;
}
.input_area {
    width: 100%;
    padding: 5px;
}
.select_wrapper {
    position: relative;
    width: 100%;
    border: 1px solid $description-color;
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    padding: 0.8rem 1rem 0.8rem 2rem;
    margin-right: 2rem;
}
.select {
    width: 100%;
    color: $description-color;
    padding: 5px;
}
.checkbox_wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 3rem;
    @media only screen and (max-width: $bp-medium) {
        flex-direction: column;
        gap: 2.5rem;
    }
}
.radio_btn_wrapper {
    display: flex;
    width: 100%;
    border: 1px solid $description-color;
    border-radius: 0.5rem;
    position: relative;
}
.radio_btn_wrapper:first-child {
    margin-right: 2rem;
}
.radio_btn_wrapper input:checked + div {
    background: $dark-color;
}
.radio_btn_wrapper input:checked + div h2 {
    color: white;
}
.radio_btn {
    accent-color: $description-color;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 1.6rem;
    transform: translateY(-50%);
}
.btn_text {
    padding: 3rem 0 3rem 7rem;
    width: 100%;
}
.checkbox_title {
    color: $dark-color;
    font-size: 18px;
    margin-bottom: 1.2rem;
}
.checkbox_text {
    color: $description-color;
    font-size: $description-fs;
}
.payBtn {
    display: inline-block;
    @include muli-font-normal;
    font-size: 1.8rem;
    line-height: 4.8rem;
    letter-spacing: 0.04em;
    background: white;
    position: relative;
    padding-left: 2rem;
    color: #373f41;
    @media only screen and (max-width: $bp-small) {
        position: absolute;
        bottom: 0;
    }
}
.payBtn::before {
    content: url('https://res.cloudinary.com/dm2s5stjy/image/upload/v1677499637/Linens/SVG/VectorBig_imqj8a.svg');
    position: absolute;
    top: 0.1rem;
    left: 0.2rem;
}
