@import '../../../style/variables';
@import '../../../style/mixins';

.carousel {
    padding: 2rem 0 2.5rem;

    &Info {
        text-align: left;
        position: absolute;
        left: 5rem;
        bottom: 5rem;
        @media only screen and (max-width: $bp-medium) {
            left: 3.5rem;
            bottom: 2.5rem;
        }
        @media only screen and (max-width: $bp-small) {
            left: 2.5rem;
            bottom: 1.5rem;
        }
    }

    &Heading {
        padding: 1.2rem;
        max-width: max-content;
        @media only screen and (max-width: $bp-medium) {
            font-size: 1.6rem !important;
        }
    }

    &Text {
        border-radius: 3px;
    }

    &TextDescription {
        max-width: 70%;
        margin: 0.8rem 0;
        padding: 0.7rem 1.2rem;
        @media only screen and (max-width: $bp-medium) {
            font-size: 1.3rem !important;
        }
        @media only screen and (max-width: $bp-small) {
            padding: 0.5rem 0.7rem;
        }
    }

    &Link {
        @include abel-font-normal;
        background-color: $light-color;
        color: $dark-color;
        display: inline-block;
        font-size: 1.6rem;
        text-transform: uppercase;
        padding: 1.8rem 3.4rem;
        border-radius: 3px;
        letter-spacing: 1px;
        transition: all 0.25s ease-in;
        @media only screen and (max-width: $bp-small) {
            padding: 0.9rem 1.2rem;
        }

        &:hover {
            opacity: 0.7;
        }
    }

    & :global .carousel.carousel-slider .control-arrow {
        width: 5rem;
        height: 8.5rem;
    }

    & :global .carousel .slide img {
        width: 99.7%;
    }

    & :global .carousel .selected img {
        width: 100%;
    }
}

:global .carousel .control-dots {
    bottom: -2.5rem !important;
    margin: 0 !important;
    max-width: 58.2rem !important;
    left: 50%;
    transform: translateX(-50%);
    border-top: 1px solid $dark-color;
    display: flex;
}

:global .carousel .control-dots .dot {
    opacity: 0 !important;
    width: 19.4rem !important;
    height: 5px !important;
    margin: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background: $dark-color !important;
}

:global .carousel .control-dots .dot.selected,
:global .carousel .control-dots .dot:hover {
    opacity: 1 !important;
}
