@import '../../../../style/mixins';
@import '../../../../style/variables';

.inputs {
    margin-top: 1.6rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    &Wrapper {
        & > span {
            margin-right: 1rem;
            width: 5rem;
        }
    }

    &Wrapper {
        display: flex;
        align-items: center;
    }
    & span {
        @include muli-font-normal;
        font-size: $description-fs;
        color: $dark-color;
    }
    & input {
        color: $dark-color;
        padding: 0.1rem 0rem;
        text-align: center;
        width: 8rem;
        border: 1px solid $dark-color;
        display: inline;
        @include muli-font-normal;
        font-size: $description-fs;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }
}

.submitBtn {
    @include abel-font-normal;
    font-size: 1.5rem;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid $dark-color;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    transition: all 0.25s ease-in;
    cursor: pointer;

    &:hover {
        background-color: $dark-color;
        color: $light-color;
    }
}
